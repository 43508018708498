// SPDX-FileCopyrightText: OpenTalk GmbH <mail@opentalk.eu>
//
// SPDX-License-Identifier: EUPL-1.2
import { useMediaDeviceSelect } from '@livekit/components-react';
import { Room } from 'livekit-client';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CameraOffIcon, CameraOnIcon } from '../../../assets/icons';
import { showConsentNotification } from '../../../commonComponents';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { useManageVideoEffect } from '../../../hooks/useManageVideoEffect';
import { useMediaChoices } from '../../../provider/MediaChoicesProvider';
import { selectNeedRecordingConsent } from '../../../store/slices/streamingSlice';
import { ToolbarButtonIds } from '../Toolbar';
import ToolbarButton from './ToolbarButton';
import VideoMenu from './VideoMenu';

interface VideoButtonProps {
  isLobby?: boolean;
  videoEnabled: boolean;
}

const VideoButton = ({ isLobby = false, videoEnabled }: VideoButtonProps) => {
  const { t } = useTranslation();
  const mediaChoices = useMediaChoices();
  const askConsent = useAppSelector(selectNeedRecordingConsent);
  const dispatch = useAppDispatch();

  !isLobby && useManageVideoEffect();

  const menuRef = useRef<HTMLDivElement>(null);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [permissionDenied, setPermissionDenied] = useState<boolean | 'pending'>(false);

  const { devices, setActiveMediaDevice } = useMediaDeviceSelect({
    kind: 'videoinput',
    requestPermissions: false,
  });

  const checkMediaPermissions = async (deviceId = mediaChoices?.userChoices.videoDeviceId) => {
    const constraints: MediaStreamConstraints = {
      video: {
        deviceId,
      },
    };
    setPermissionDenied('pending');

    const stream = await navigator.mediaDevices.getUserMedia(constraints);
    const selectedDeviceId = stream.getVideoTracks()?.[0].getSettings().deviceId?.toString();
    setPermissionDenied(false);

    if (selectedDeviceId && selectedDeviceId !== mediaChoices?.userChoices.videoDeviceId) {
      mediaChoices?.saveVideoInputDeviceId(selectedDeviceId);
      await setActiveMediaDevice(selectedDeviceId);
    }

    const localDevices = await Room.getLocalDevices('videoinput', true);

    return localDevices;
  };

  const onClick = async () => {
    if (askConsent && !mediaChoices?.userChoices.videoEnabled) {
      const consent = await showConsentNotification(dispatch);
      if (!consent) {
        return;
      }
    }

    if (mediaChoices?.userChoices.videoEnabled) {
      mediaChoices.saveVideoInputEnabled(false);
    } else {
      try {
        await checkMediaPermissions();
        mediaChoices?.saveVideoInputEnabled(true);
      } catch (err) {
        setPermissionDenied(true);
        if (err instanceof Error) {
          console.error('Permission or video toggle failed:', err);
        }
      }
    }
  };

  const tooltipText = () => {
    if (permissionDenied === true) {
      return t('device-permission-denied');
    }
    if (videoEnabled) {
      return t('toolbar-button-video-turn-off-tooltip-title');
    }
    return t('toolbar-button-video-turn-on-tooltip-title');
  };

  const pendingPermission = permissionDenied === 'pending';

  return (
    <div ref={menuRef}>
      <ToolbarButton
        tooltipTitle={tooltipText()}
        onClick={onClick}
        hasContext
        contextDisabled={pendingPermission || devices.length === 0}
        contextTitle={t('toolbar-button-video-context-title')}
        contextMenuId="video-context-menu"
        contextMenuExpanded={showMenu}
        disabled={pendingPermission || devices.length === 0}
        active={videoEnabled}
        openMenu={() => {
          setShowMenu(true);
        }}
        isLobby={isLobby}
        data-testid="toolbarVideoButton"
        id={ToolbarButtonIds.Video}
      >
        {videoEnabled ? <CameraOnIcon /> : <CameraOffIcon />}
      </ToolbarButton>
      <VideoMenu
        anchorEl={menuRef.current}
        onClose={() => {
          setShowMenu(false);
        }}
        open={showMenu}
        permissionDenied={permissionDenied}
        videoEnabled={videoEnabled}
        isLobby={isLobby}
        checkMediaPermissions={checkMediaPermissions}
      />
    </div>
  );
};

export default VideoButton;
