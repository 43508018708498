// SPDX-FileCopyrightText: OpenTalk GmbH <mail@opentalk.eu>
//
// SPDX-License-Identifier: EUPL-1.2
import { isWeb } from '@livekit/components-core';
import { LiveKitRoom } from '@livekit/components-react';
import { styled } from '@mui/material';
import { XORCipher, selectIsAuthenticated } from '@opentalk/redux-oidc';
import { RoomId } from '@opentalk/rest-api-rtk-query';
import {
  DeviceUnsupportedError,
  E2EEOptions,
  ExternalE2EEKeyProvider,
  Room,
  RoomOptions,
  VideoPresets,
  isE2EESupported,
} from 'livekit-client';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useGetRoomEventInfoQuery } from '../../api/rest';
import { notifications } from '../../commonComponents';
import OpentalkError from '../../components/Error';
import LobbyView from '../../components/LobbyView';
import { useAppSelector } from '../../hooks';
import { useInviteCode } from '../../hooks/useInviteCode';
import { selectLivekitE2EESalt, selectLivekitServerUrl } from '../../store/slices/configSlice';
import { selectLivekitUnavailable, setLivekitRoom } from '../../store/slices/livekitSlice';
import { selectLivekitAccessToken } from '../../store/slices/livekitSlice';
import { ConnectionState, selectRoomConnectionState } from '../../store/slices/roomSlice';
import RoomLoadingView from './fragments/RoomLoadingView';

const MeetingView = React.lazy(() => import('../../components/MeetingView'));
const WaitingView = React.lazy(() => import('../../components/WaitingView'));

const RoomContainer = styled(LiveKitRoom)(() => {
  return {
    display: 'contents',
  };
});

const RoomPage = () => {
  const { t } = useTranslation();

  const inviteCode = useInviteCode();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const connectionState: ConnectionState = useAppSelector(selectRoomConnectionState);
  const livekitAccessToken: string | undefined = useAppSelector(selectLivekitAccessToken);
  const serverUrl = useAppSelector(selectLivekitServerUrl);
  const e2eeSalt = useAppSelector(selectLivekitE2EESalt);
  const isLivekitUnavailable = useAppSelector(selectLivekitUnavailable);

  const { roomId } = useParams<'roomId'>() as {
    roomId: RoomId;
  };
  const { data: roomData } = useGetRoomEventInfoQuery({ id: roomId, inviteCode: inviteCode }, { skip: !roomId });

  const keyProvider = useMemo(() => new ExternalE2EEKeyProvider(), []);
  const e2eePassphrase = useMemo(
    () => XORCipher.handle(`${roomData?.id}${roomData?.roomId}${e2eeSalt || ''}`),
    [roomData, e2eeSalt]
  );

  const [worker, setWorker] = useState<Worker | null>(null);
  useEffect(() => {
    if (!worker && e2eePassphrase) {
      const newWorker = new Worker(new URL('livekit-client/e2ee-worker', `file://${__filename}`));
      setWorker(newWorker);
    }
    () => {
      worker?.terminate();
      setWorker(null);
    };
  }, [e2eePassphrase, worker]);

  const e2eeEnabled = useMemo(() => {
    return (roomData?.e2EEncryption || false) && !!(e2eePassphrase && worker) && isE2EESupported();
  }, [roomData, e2eePassphrase, worker]);

  const roomOptions = useMemo((): RoomOptions | undefined => {
    if (!livekitAccessToken) {
      return undefined;
    }

    return {
      publishDefaults: {
        /*
         * Up to two additional simulcast layers to publish in addition to the original
         * Track.
         * When left blank, it defaults to h180, h360.
         * If a SVC codec is used (VP9 or AV1), this field has no effect.
         * videoSimulcastLayers: [VideoPresets.h1080, VideoPresets.h720],
         * */
        red: !e2eeEnabled,
        simulcast: true,
      },
      dynacast: true,
      disconnectOnPageLeave: false,
      adaptiveStream: true,
      videoCaptureDefaults: {
        resolution: VideoPresets.h720.resolution,
      },
      e2ee: e2eeEnabled
        ? ({
            keyProvider,
            worker,
          } as E2EEOptions)
        : undefined,
    };
  }, [keyProvider, worker, e2eeEnabled, livekitAccessToken]);

  const room = useMemo(() => {
    const roomInstance = new Room(roomOptions);
    setLivekitRoom(roomInstance);
    return roomInstance;
  }, [roomOptions]);

  if (e2eeEnabled && !room.isE2EEEnabled) {
    keyProvider.setKey(e2eePassphrase);
    room.setE2EEEnabled(true).catch((e) => {
      if (e instanceof DeviceUnsupportedError) {
        notifications.error(t('unsupported-browser-e2e-encryption-dialog-message'));
      }
    });
  }

  useEffect(() => {
    const onPageLeave = async () => {
      await room.disconnect();
    };

    if (isWeb()) {
      window.addEventListener('beforeunload', onPageLeave);
    }

    return () => {
      if (isWeb()) {
        window.removeEventListener('beforeunload', onPageLeave);
      }
    };
  }, [room]);

  const renderRoom = useMemo(() => {
    if (!isAuthenticated && !inviteCode) {
      console.warn('meeting page - not logged in - redirect');
      return <LobbyView />;
    }
    switch (connectionState) {
      // Regular state machine flow
      case ConnectionState.Initial:
      case ConnectionState.Setup:
      case ConnectionState.FailedCredentials:
      case ConnectionState.Left:
        return <LobbyView />;
      case ConnectionState.Starting:
      case ConnectionState.Failed:
        return <RoomLoadingView />;
      case ConnectionState.Online:
      case ConnectionState.Leaving:
        return <MeetingView />;
      // Exception states
      case ConnectionState.ReadyToEnter:
      case ConnectionState.Waiting:
        return <WaitingView />;
      case ConnectionState.Blocked:
        return <RoomLoadingView />;
      default:
        console.error('room state unknown', connectionState);
        return <LobbyView />;
    }
  }, [connectionState, inviteCode, isAuthenticated]);

  if (isLivekitUnavailable) {
    return <OpentalkError title={t('error-livekit-unavailable')} />;
  }

  return (
    <>
      {room && (
        <RoomContainer room={room} token={livekitAccessToken} serverUrl={serverUrl} video={false} audio={false}>
          {renderRoom}
        </RoomContainer>
      )}
    </>
  );
};

export default RoomPage;
