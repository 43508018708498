// SPDX-FileCopyrightText: OpenTalk GmbH <mail@opentalk.eu>
//
// SPDX-License-Identifier: EUPL-1.2
import { useMediaDeviceSelect } from '@livekit/components-react';
import { styled } from '@mui/material';
import { LocalAudioTrack, Room } from 'livekit-client';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MicOffIcon, MicOnIcon } from '../../../assets/icons';
import { showConsentNotification } from '../../../commonComponents';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { useMediaChoices } from '../../../provider/MediaChoicesProvider';
import { selectNeedRecordingConsent } from '../../../store/slices/streamingSlice';
import { ToolbarButtonIds } from '../Toolbar';
import AudioIndicator from './AudioIndicator';
import AudioMenu from './AudioMenu';
import ToolbarButton from './ToolbarButton';

const MicOnStyled = styled(MicOnIcon)({
  zIndex: 1,
});

interface AudioButtonProps {
  localAudioTrack?: LocalAudioTrack;
  isLobby?: boolean;
  microphoneEnabled: boolean;
}

const AudioButton = ({ localAudioTrack, isLobby = false, microphoneEnabled }: AudioButtonProps) => {
  const mediaChoices = useMediaChoices();
  const { t } = useTranslation();
  const askConsent = useAppSelector(selectNeedRecordingConsent);
  const dispatch = useAppDispatch();

  const menuRef = useRef<HTMLDivElement>(null);
  const [showMenu, setShowMenu] = useState(false);
  const [permissionDenied, setPermissionDenied] = useState<boolean | 'pending'>(false);

  const { devices, setActiveMediaDevice } = useMediaDeviceSelect({
    kind: 'audioinput',
    requestPermissions: false,
  });

  const checkMediaPermissions = async (deviceId = mediaChoices?.userChoices.audioDeviceId) => {
    const constraints: MediaStreamConstraints = {
      audio: {
        deviceId,
      },
    };
    setPermissionDenied('pending');

    const stream = await navigator.mediaDevices.getUserMedia(constraints);
    const selectedDeviceId = stream.getAudioTracks()?.[0].getSettings().deviceId?.toString();
    setPermissionDenied(false);

    if (selectedDeviceId && selectedDeviceId !== mediaChoices?.userChoices.audioDeviceId) {
      mediaChoices?.saveAudioInputDeviceId(selectedDeviceId);
      await setActiveMediaDevice(selectedDeviceId);
    }

    const localDevices = await Room.getLocalDevices('audioinput', true);

    return localDevices;
  };

  const onClick = async () => {
    if (askConsent && !mediaChoices?.userChoices.audioEnabled) {
      const consent = await showConsentNotification(dispatch);
      if (!consent) {
        return;
      }
    }

    if (mediaChoices?.userChoices.audioEnabled) {
      mediaChoices.saveAudioInputEnabled(false);
    } else {
      try {
        await checkMediaPermissions();
        mediaChoices?.saveAudioInputEnabled(true);
      } catch (err) {
        setPermissionDenied(true);
        if (err instanceof Error) {
          console.error('Permission or audio toggle failed:', err.message);
        }
      }
    }
  };

  const indicator = useMemo(
    () =>
      microphoneEnabled && localAudioTrack ? (
        <>
          <AudioIndicator shape="circle" localAudioTrack={localAudioTrack} />
          <MicOnStyled data-testid="toolbarAudioButtonOn" />
        </>
      ) : (
        <MicOffIcon data-testid="toolbarAudioButtonOff" />
      ),
    [microphoneEnabled, localAudioTrack]
  );

  const tooltipText = () => {
    if (permissionDenied === true) {
      return t('device-permission-denied');
    }
    if (microphoneEnabled) {
      return t('toolbar-button-audio-turn-off-tooltip-title');
    }
    return t('toolbar-button-audio-turn-on-tooltip-title');
  };

  const pendingPermission = permissionDenied === 'pending';

  return (
    <div ref={menuRef}>
      <ToolbarButton
        tooltipTitle={tooltipText()}
        onClick={onClick}
        hasContext
        contextDisabled={pendingPermission || devices.length === 0}
        disabled={pendingPermission || devices.length === 0}
        openMenu={() => setShowMenu(true)}
        active={microphoneEnabled}
        isLobby={isLobby}
        data-testid="toolbarAudioButton"
        contextTitle={t('toolbar-button-audio-context-title')}
        contextMenuId="audio-context-menu"
        contextMenuExpanded={showMenu}
        id={ToolbarButtonIds.Audio}
      >
        {indicator}
      </ToolbarButton>
      <AudioMenu
        open={showMenu}
        onClose={() => setShowMenu(false)}
        anchorEl={menuRef.current}
        permissionDenied={permissionDenied}
        checkMediaPermissions={checkMediaPermissions}
      />
    </div>
  );
};

export default AudioButton;
