// SPDX-FileCopyrightText: OpenTalk GmbH <mail@opentalk.eu>
//
// SPDX-License-Identifier: EUPL-1.2
import { ListItemText, ThemeProvider, Typography, styled } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ErrorIcon, MicOnIcon, WarningIcon } from '../../../assets/icons';
import { createOpenTalkTheme } from '../../../assets/themes/opentalk';
import { useFullscreenContext } from '../../../hooks/useFullscreenContext';
import { useMediaChoices } from '../../../provider/MediaChoicesProvider';
import { DeviceId } from '../../../types/device';
import DeviceList from './DeviceList';
import { MenuSectionTitle, ToolbarMenu, ToolbarMenuProps } from './ToolbarMenuUtils';

const MultilineTypography = styled(Typography)({
  whiteSpace: 'pre-wrap',
});

interface AudioMenuProps extends ToolbarMenuProps {
  permissionDenied: boolean | 'pending';
  checkMediaPermissions: (deviceId?: string | undefined) => Promise<MediaDeviceInfo[]>;
}

const AudioMenu = ({ anchorEl, onClose, open, permissionDenied, checkMediaPermissions }: AudioMenuProps) => {
  const { t } = useTranslation();
  const mediaChoices = useMediaChoices();

  const [requestedPermissions, setRequestedPermissions] = useState(false);
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);

  useEffect(() => {
    const gatherDevices = async () => {
      const localDevices = await checkMediaPermissions();
      setDevices(localDevices);
      setRequestedPermissions(true);
    };

    if (open && devices.length === 0 && !requestedPermissions) {
      gatherDevices();
    }
  }, [open, devices.length, requestedPermissions]);

  // Some browsers (e.g. Firefox) duplicate devices, so we need to filter them out
  const filteredDevices = useMemo(() => {
    const seenDeviceIds = new Set<string>();

    return devices
      .filter((device) => {
        if (device.deviceId === '' || seenDeviceIds.has(device.deviceId)) {
          return false;
        }
        seenDeviceIds.add(device.deviceId);
        return true;
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [devices]);

  const selectedDeviceId = mediaChoices?.userChoices.audioDeviceId;

  const fullscreenHandle = useFullscreenContext();

  const handleClick = async (deviceId: DeviceId) => {
    if (deviceId !== selectedDeviceId) {
      await checkMediaPermissions(deviceId);
    }
  };

  useEffect(() => {
    if (mediaChoices?.userChoices.audioDeviceId === '' && filteredDevices[0]?.deviceId) {
      mediaChoices.saveAudioInputDeviceId(filteredDevices[0].deviceId);
    }
  }, [filteredDevices, mediaChoices]);

  // Todo show spinner while we fetch the permissions?
  return (
    <ThemeProvider theme={createOpenTalkTheme()}>
      <ToolbarMenu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: -4,
          horizontal: 'center',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        disablePortal={fullscreenHandle.active}
        id="audio-context-menu"
        aria-labelledby="audio-menu-title"
        role="listbox"
      >
        <MenuSectionTitle id="audio-menu-title" sx={{ pt: 1.5, pb: 1.5 }}>
          <MicOnIcon />
          {t('audiomenu-choose-input')}
        </MenuSectionTitle>

        {permissionDenied === true && (
          <MenuSectionTitle>
            <ErrorIcon />
            <MultilineTypography variant="body2">{t('device-permission-denied')}</MultilineTypography>
          </MenuSectionTitle>
        )}

        {filteredDevices.length === 0 && permissionDenied === 'pending' ? (
          <MenuSectionTitle>
            <WarningIcon />
            <ListItemText>{t('devicemenu-wait-for-permission')}</ListItemText>
          </MenuSectionTitle>
        ) : (
          <DeviceList
            devices={filteredDevices}
            selectedDevice={selectedDeviceId as DeviceId | undefined}
            onClick={handleClick}
            ariaLabelId="audio-menu-title"
          />
        )}
      </ToolbarMenu>
    </ThemeProvider>
  );
};

export default AudioMenu;
